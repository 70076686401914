import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { AnalyticsCategory } from '../../foundation/enums/AnalyticsCategory'
import { ButtonLink } from '../presentation/components/ButtonLink'
import 'bootstrap/dist/css/bootstrap.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { BookList } from '../presentation/components/BookList'
import { Container, Row } from 'reactstrap'

library.add(fas)

const IndexPage: React.FC<PageProps> = ({ data, pageContext }) => {
	console.log(`Data is`, data)
	console.log(`context is`, pageContext)
	if (!data) {
		return null
	}
	const queryData = data as any
	const { edges: recentReleases } = queryData.recentReleases
	const { edges: upcomingReleases } = queryData.upcomingReleases
	return (
		<Container>
			<Row>
				<img className="m-4" src="/images/outyet.svg" />
			</Row>
			<Row>
				<h1 className="my-4 col-12" style={{ color: 'white' }}>
					Recent Releases
				</h1>
			</Row>
			<Row>
				<BookList books={recentReleases.map(r => r.node)} />
			</Row>
			<Row>
				<h1 className="my-4 col-12" style={{ color: 'white' }}>
					Upcoming Releases
				</h1>
			</Row>
			<Row>
				<BookList books={upcomingReleases.map(r => r.node)} />
			</Row>
		</Container>
	)
}

export default IndexPage

export const pageQuery = graphql`
	query($today: Date!) {
		recentReleases: allContentfulBook(
			sort: { fields: release_date, order: DESC }
			filter: { release_date: { lte: $today } }
		) {
			edges {
				node {
					id
					cover {
						localFile {
							publicURL
						}
					}
					slug
					release_date
					title
					genres {
						id
						slug
						link
						genreName
						icon
					}
					author {
						picture {
							localFile {
								publicURL
							}
						}
						id
						name
						link
						social_facebook
						social_twitter
					}
					link_ebook
					link_audiobook
					link_paperback
					link_hardcover
					series {
						title
						slug
						link
						hashtags
					}
					series_index
				}
			}
		}
		upcomingReleases: allContentfulBook(
			limit: 10
			sort: { fields: [release_date], order: ASC }
			filter: { release_date: { gt: $today } }
		) {
			edges {
				node {
					id
					cover {
						localFile {
							publicURL
						}
					}
					slug
					release_date
					title
					genres {
						id
						slug
						link
						genreName
						icon
					}
					author {
						picture {
							localFile {
								publicURL
							}
						}
						id
						name
						link
						social_facebook
						social_twitter
					}
					link_ebook
					link_audiobook
					link_paperback
					link_hardcover
					series {
						title
						slug
						link
						hashtags
					}
					series_index
				}
			}
		}

		site {
			siteMetadata {
				siteUrl: url
			}
		}
	}
`
