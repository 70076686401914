import { Link } from 'gatsby'
import React from 'react'
import {
	Card,
	CardFooter,
	CardImg,
	CardImgOverlay,
	CardText,
	CardTitle,
} from 'reactstrap'
import { Book } from '../../../../foundation/types/Book'

export const BookListItem: React.FC<{ book: Book }> = ({ book }) => {
	const imageUrl = book.cover
		? book.cover.localFile.publicURL
		: '/images/cover.jpg'
	return (
		<Card color="transparent" inverse className="col-4 col-lg-3 m-3">
			<Link to={`/books/${book.slug}`}>
				<CardImg top src={imageUrl} width="80%" style={{ width: '80%' }} />
			</Link>
			<CardText style={{ fontWeight: 'bold' }}>
				{book.title}
				<br />
				{book.release_date}
			</CardText>
		</Card>
	)
}
