import React from 'react'
import { CardGroup } from 'reactstrap'
import { Book } from '../../../../foundation/types/Book'
import { BookListItem } from './BookListItem'

export const BookList: React.FC<{ books: Book[] }> = ({ books }) => {
	return (
		<>
			{books.map(b => (
				<BookListItem book={b} key={b.id} />
			))}
		</>
	)
}
